<template id="tmplDashboard">
  <div id="divDashboard">
    <b-row id="rowWelcome">
      <b-col id="colWelcome">
        <div id="dvWelcome" class="text-center wecome-div">
          <div id="dvCannotViewOrders" v-if="!canViewOrders">
            <h2 id="txtFutrueHome">This is the future home of your dashboard.</h2>
            <h5 id="txtMenuToLeft">Please use the menu to the left to navigate around.</h5>
            <img id="imgTruck" class="img-fluid loader-gif" src="img/truck.gif" alt="Truck image." />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-card id="crdCanViewOrders" v-if="canViewOrders" class="w-100 h-100" style="min-height:300px;">
      <loading
        id="ldgIsLoading"
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <div id="divConsignees" v-if="!isLoading">
        <b-row id="rowConsignees" class="mb-3">
          <b-col id="colconsignees" sm="6">
            <h4 txtConsignees>
              Consignees

              <span
                id="spnIsLoading"
                v-if="isReloading"
                class="spinner-border spinner-border-sm ml-1"
                style="vertical-align: baseline"
                role="status"
                aria-hidden="true"
              ></span>
            </h4>
          </b-col>

          <b-col id="colFiltersSlideOut" sm="6" class="text-right">
            <b-button
              id="btnFilterSlideOut"
              class="btn-block-xs-only"
              @click="showFiltersSlideOut()"
              :variant="filterButtonClass"
            >
              {{ filterButtonText }}
            </b-button>
          </b-col>
          <b-col id="colReturnsWarning" cols="12" class="mt-2">
            <b-alert 
              id="altFilterToManyReturns"
              variant="warning"
              :show="consignees.length >= consigneeDashboardMessageThreshold"
              dismissible
            >
              Only the first {{ consigneeDashboardMessageThreshold }} consignees
              have been returned. Please use/refine the advanced filters to
              retrieve a smaller dataset.
            </b-alert>
          </b-col>

          <b-col id="colConsigneesReturned" cols="12" class="mt-2">
            <b-alert
              id="altFliterNoconsigneesReturned"
              variant="warning"
              :show="consignees.length == 0"
              dismissible
            >
              No consignees have been returned.
              <span id="spnRefineFilters" v-if="isFilterApplied">
                Please refine the advanced filters to retrieve a different
                dataset.
              </span>
              If you believe this is an error please contact support.
            </b-alert>
          </b-col>
        </b-row>

        <b-row id="rowMapView" class="mb-4">
          <b-col id="colMapView" cols="12">
            <HereMapConsigneeView
              id="hmcMapConsigneeView"
              apiKey="ZDGwdcxJZDMLvL8752hwfICLfMc8RrVs2n210tnSumU"
              lat="37.7397"
              lng="-121.4252"
              width="100%"
              height="500px"
              v-if="canViewMaps"
              :mapJson="consignees"
            />
          </b-col>
        </b-row>
        <b-row id="rowActiveOrders">
          <b-col id="colActiveOrders" md="12">
            <h4 id="txtActiveOrders" v-if="orders && orders.length > 0">Active Orders</h4>

            <b-card id="crdViewOtherOrders" v-if="!orders || orders.length == 0">
              <p id="parNoOrdersPlanned">
                <b id="txtBldNoOrdersPlanned">
                  There are currently no orders in Planned, Dispatched or
                  Started status for the returned Consignees.
                </b>
              </p>

              <p id="parviewOtherOrders">
                <b-button id="btnviewOtherOrders" variant="outline-primary" @click="navigateToOrders">View Other Orders</b-button>
              </p>
            </b-card>

            <OrderTenderCard id="crdOrderTenders" v-if="orders && orders.length > 0"
                             :orders="paginatedItems"
                             :showChecks="false"></OrderTenderCard>

            <b-pagination
                          id="pgnDashboardOrders"
                          v-model="currentPage"
                          @change="onPageChanged"
                          :total-rows="rows"
                          :per-page="perPage"
                          first-number
                          last-number
                          aria-controls="my-table"
                          v-if="orders && orders.length > 0"></b-pagination>
            <b-row id="rowTotalOrders">Displaying Orders {{ orderStart }} to {{ ordersEnd }} of {{ rows }}</b-row>
          </b-col>
        </b-row>

      </div>
    </b-card>
    <slideout-panel id="sldotPnlBlank"></slideout-panel>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex";
import OrderTenderCard from "@/components/order-tender/ViewOrderTenderCard";
import HereMapConsigneeView from "@/components/dashboard/HereMapConsigneeView";
import {
  canCreateAdHocOrders,
  canViewOrders,
  canViewOrderMaps
} from "@/shared/utils/permissions";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const companiesRepository = RepositoryFactory.get("companiesRepository");
const flattenedFiltersRepository = RepositoryFactory.get(
  "flattenedFiltersRepository"
);
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FilterDropDownMenu from "@/components/dashboard/FilterDropdownMenu";
import { consigneeDashboardMessageThreshold } from "@/shared/constants";

export default {
  name: "Dashboard.vue",
  components: {
    OrderTenderCard,
    HereMapConsigneeView,
    Loading,
    FilterDropDownMenu
  },
  data() {
    return {
      isLoading: false,
      isReloading: false,
      orders: [],
      orders2: [],
      consignees: [],
      perPage: 10,
      currentPage: 1,
      paginatedItems: [],
      refreshInterval: null,
      hasBillTos: false,
      hasAccountOfs: false,
      hasSuppliers: false,
      orderStart: 1,
      ordersEnd: 20
    };
  },
  async mounted() {
    this.isLoading = true;
  
    await this.loadConsignees();
		await this.fetchUserAssignedRefNumTypes();

    this.isLoading = false;

    this.refreshInterval = setInterval(() => {
      this.loadConsignees();
    }, 5 * 60 * 1000);
  },

  methods: {
		...mapActions("user", ["fetchUserAssignedRefNumTypes"]),
    async loadConsignees() {
      this.isReloading = true;
      //searchTerm, city, state, includeAccountOfConsignees = false, includeSupplierOfConsignees = false, withActiveOrdersOnly = true, skip = 0, take = 100
      let f = await flattenedFiltersRepository.get();

      var selectedConsignees = [];
      var selectedCities = [];
      var selectedStates = [];
      var selectedDivision = [];

      if (this.filterObject.selectedConsignees) {
        selectedConsignees = this.filterObject.selectedConsignees.map(
          c => c.id
        );
      }
 
      if (this.filterObject.selectedDivision) {
        selectedDivision = this.filterObject.selectedDivision.map(d => d);
      }

      if (this.filterObject.selectedCities) {
        selectedCities = this.filterObject.selectedCities.map(c => c);
      }

      if (this.filterObject.selectedStates) {
        selectedStates = this.filterObject.selectedStates.map(
          c => c.abbreviation
        );
      }

      //  async getConsignees(searchTerm, id, city, state, includeAccountOfConsignees = false, includeSupplierOfConsignees = false, withActiveOrdersOnly = true, skip = 0, take = 100) {
      let a = await companiesRepository.getConsignees(
        "",
        selectedConsignees,
        selectedCities,
        selectedStates,
        this.filterObject.includeBillToConsignees,
        this.filterObject.includeAccountOfConsignees,
        this.filterObject.includeSupplierOfConsignees,
        this.filterObject.activeOrdersOnly,
        0,
        this.consigneeDashboardMessageThreshold,
        selectedDivision
      );

      this.hasBillTos =	f.data.filter(vendor => vendor.billTo != null).length > 0;
					
      this.hasAccountOfs = f.data.filter(vendor => vendor.accountOf != null).length > 0;

      this.hasSuppliers = f.data.filter(vendor => vendor.supplier != null).length > 0;

      if (a.success) {        
        this.consignees = a.data;
        this.orders = [];

        if (this.consignees.length > 0) {
          this.consignees.forEach(element => {
            if (element.orders && element.orders.length > 0) {
              element.orders.forEach(order => {
                order.consignees.forEach(c => {
                  if (c.id == element.id) {
                    c.latitude = element.latSeconds;
                    c.longitude = element.longSeconds;
                  }
                });

                var exists = this.orders.find(
                  o => o.tmsOrderNumber == order.tmsOrderNumber
                );
                if (!exists) this.orders.push(order);
              });
            }
          });
          this.paginate(this.perPage, 0);
        }

        this.orders.forEach(order => {
          if (order.billTo === "GETGO") {
            order.division = "SPG";
          }
        });
      } else {
        this.$snotify.error(
          "There was an error retrieving consignee data.",
          "Error"
        );
      }
     
      this.isReloading = false;
    },

    paginate(page_size, page_number) {
      let itemsToParse = this.orders;
      this.paginatedItems = itemsToParse.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );
      this.orderStart = this.paginatedItems.length
      this.orderStart = (this.perPage * (page_number + 1)) - (this.perPage - 1)
      if ((this.perPage * (page_number + 1)) <= this.rows) {
        this.ordersEnd = this.perPage * (page_number + 1)
      }
      else {
        this.ordersEnd = this.rows
      }
     
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1);
      
    },
    navigateToOrders() {
      this.$router.push({
        name: "order-tenders"
      });
    },
    showFiltersSlideOut() {
      const panelInstance = this.$showPanel({
        component: FilterDropDownMenu,
        props: {
          hasAccountOfs: this.hasAccountOfs,
          hasSuppliers: this.hasSuppliers,
          hasBillTos: this.hasBillTos
        },
				openOn: 'right'
      });

      panelInstance.promise.then(result => {
        if (result) this.loadConsignees();
      });
    }
  },
  computed: {
    ...mapState("dashboard", ["isFilterApplied", "filterObject"]),
		
    rows() {
      return this.orders.length;
    },
    canTenderOrders() {
      return canCreateAdHocOrders(this.$store);
    },
    canViewOrders() {
      return canViewOrders(this.$store);
    },

    canViewMaps() {
      return canViewOrderMaps(this.$store);
    },
    filterButtonClass() {
      if (this.isFilterApplied) {
        return "success";
      }

      return "outline-primary";
    },
    filterButtonText() {
      if (this.isFilterApplied) {
        return "View Applied Filters";
      }

      return "Advanced Filters";
    },
    consigneeDashboardMessageThreshold() {
      return consigneeDashboardMessageThreshold;
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  }
};
</script>

<style scoped>
.wecome-div {
  padding-top: 3vh;
}

.loader-gif {
  margin-top: 3vh;
  margin-bottom: 3vh;
  border-radius: 15px;
  max-height: 350px;
}
</style>