<template>
  <div class="infoBubble" style="font-size:.8rem">
    <div class="pb-2">
      <b>{{ consignee.name }} ({{ consignee.id }})</b>
      <br />

      <small>
        {{ consignee.address }}, {{ consignee.city }}, {{ consignee.state }}
        {{ consignee.zip }}
      </small>
    </div>

    <div class="pb-2">
      <b>
        <span v-if="nextOrder">
          Next Order #
          <a
            href="#"
            @click="navigateToOrderDetails(nextOrder.tmsOrderNumber, nextOrder.division)"
            >{{ nextOrder.tmsOrderNumber }}</a
          >
          ( Status: {{ orderStatus(nextOrder.orderStatus) }})

          <div
            v-for="stop in consigneeStop(nextOrder.consignees, consignee.id)"
            v-bind:key="stop.id"
          >
            <small>
              <b>Estimated Arrival:</b>
              {{ stop.arrivalDate }} /
              <b>Departure:</b>
              {{ stop.departureDate }}
              <br />
              <b>Commodities:</b>
              <br />
              <div
                v-for="freight in stop.freights"
                v-bind:key="freight.freightNumber"
              >
                {{ freight.name }} ({{ freight.deliveredGrossQuantity }})
              </div>

              <!-- <span v-if="otherActiveOrders.length > 0">
          <b>Other Active Orders:</b>
          {{ otherActiveOrders }}
        </span> -->
            </small>
          </div>
        </span>
        <span v-else>
          No upcoming orders scheduled
        </span>
      </b>
    </div>
    <div class="pb-1" v-if="futureOrders && futureOrders.length > 0">
      <b>Upcoming Orders: </b>

      <span
        class="pr-1"
        v-for="order in futureOrders"
        v-bind:key="order.tmsOrderNumber"
      >
        <a href="#" @click="navigateToOrderDetails(order.tmsOrderNumber, order.division)"
          >{{ order.tmsOrderNumber }},</a
        >
      </span>
    </div>
    <!-- <div class="mb-4" v-for="stop in dropStops" v-bind:key="stop.stopNumber">
      <small>
        <b>{{ stop.type }} at:</b>
        {{ stop.company.address }}, {{ stop.company.city }},
        {{ stop.company.state }} {{ stop.company.zip }}
        <br />

        <b>Arrival:</b>
        {{ stop.arrivalDateTimeLocal }} /
        <b>Departure:</b>
        {{ stop.departureDateTimeLocal }}
        <br />
        <b>Commodities:</b>
        <br />
        <div
          v-for="freight in stop.freights"
          v-bind:key="freight.freightNumber"
        >
          {{ freight.commodity.name }} ( {{ freight.quantityGross.amount }})
        </div>

        <br />
        <span v-if="otherActiveOrders.length > 0">
          <b>Other Active Orders:</b>
          {{ otherActiveOrders }}
        </span>
      </small>
    </div> -->
    <!-- <b-button
      @click="navigateToOrderDetails(order.orderNumber,order.division)"
      variant="outline-primary"
      size="sm"
      class="mt-2 mr-2"
      >Full Order Details</b-button
    > -->

    <b-button
      v-if="canTenderOrders"
      @click="navigateToNewOrder()"
      variant="outline-primary"
      size="sm"
      class="mt-2"
      >Create new Order</b-button
    >
  </div>
</template>
<script>

import { canCreateAdHocOrders, canViewOrderMaps } from "@/shared/utils/permissions";

import _ from "lodash";

export default {
  components: {},
  props: ["orders", "consignee"],
  data() {
    return {
      freightFields: [
        "supplier",
        "accountOf",
        "commodity",
        "quantityNet",
        "quantityGross"
      ]
    };
  },
  mounted() {},
  methods: {
    navigateToOrderDetails(orderNumber, division) {
      let route = this.$router.resolve({
        name: "order-details",
        params: { 
          id: orderNumber ,
          division: division
          }
      });
      window.open(route.href, "_blank");
    },
    navigateToNewOrder() {
      let route = this.$router.resolve({
        name: "new-order-tender",
        query: { consigneeId: this.consignee.id }
      });
      window.open(route.href, "_blank");
    },
    orderStatus(val) {
      switch (val) {
        case "AVL":
          return "Available";
        case "STD":
          return "Started";
        case "DSP":
          return "Dispatched";
        case "PLN":
          return "Planned";
        case "CMP":
          return "Completed";
        case "CAN":
          return "Cancelled";
        case "PND":
          return "Pending";
          
        default:
          break;
      }
    },
    consigneeStop(orderStops, consigneeId) {
      return orderStops.filter(s => s.id == consigneeId);
    }
  },
  computed: {
    nonCompleteOrders() {
      var test = this.orders.data.filter(
        order =>
          order.orderStatus == "PLN" ||
          order.orderStatus == "STD" ||
          order.orderStatus == "DSP" ||
          order.orderStatus == "AVL"
      );

      return test;
    },
    futureOrders() {
      return this.nonCompleteOrders.slice(1);
    },
    nextOrder() {
      return this.nonCompleteOrders[0];
    },
    canTenderOrders() {
      return canCreateAdHocOrders(this.$store);
    }
  }
};
</script>