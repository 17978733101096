<template>
  <div
    class="container order-search"
    style="margin-top: 6.5vh; padding-left:2vw; padding-right:2vw"
  >
    <h2>
      Dashboard Filters
    </h2>

    <hr />

    <b-card>
      <div class="form-row  ">
        <div class="col-md-12">
          <label for="inputEmail4">Search by Consignee</label>
          <tf-consignee-multi-select
            :customerId="loggedInUserCustomerId"
            :userId="loggedInUserId"
            @updateTest="test = $event"
          ></tf-consignee-multi-select>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <label for="inputPassword4">City</label>
          <tf-cities-multi-select></tf-cities-multi-select>
        </div>

        <div class="col-md-6">
          <label for="inputEmail4">State</label>
          <tf-states-multi-select></tf-states-multi-select>
        </div>
      </div>

      <div class="form-row mt-3">
        <div class=" col-md-12">
          <b-form-checkbox
            id="chk-activeOrdersOnly"
            v-model="filter.activeOrdersOnly"
            name="chk-activeOrdersOnly"
            value="true"
            unchecked-value="false"
          >
            Include Consignees with Active Orders only
          </b-form-checkbox>

          <!-- <b-form-checkbox
            v-if="hasSuppliers || hasSuppliers"
            id="chk-includeBillToConsignees"
            v-model="filter.includeBillToConsignees"
            name="chk-includeBillToConsignees"
            value="true"
            unchecked-value="false"
          >
            Include Consignees where my Company is the BillTo
          </b-form-checkbox> -->

          <b-form-checkbox
            v-if="hasSuppliers"
            id="chk-includeSupplierOfConsignees"
            v-model="filter.includeSupplierOfConsignees"
            name="chk-includeSupplierOfConsignees"
            value="true"
            unchecked-value="false"
          >
            Include Consignees where my Company is the Supplier
          </b-form-checkbox>

          <b-form-checkbox
            v-if="hasAccountOfs"
            id="chk-includeAccountOfConsignees"
            v-model="filter.includeAccountOfConsignees"
            name="chk-includeAccountOfConsignees"
            value="true"
            unchecked-value="false"
          >
            Include Consignees where my Company is the AccountOf
          </b-form-checkbox>
        </div>
      </div>
    </b-card>

    <div class="form-row">
      <b-col>
        <b-button
          @click="applyLoadTenderFilters()"
          variant="primary"
          class="mr-3"
          >Apply Filters</b-button
        >
        <b-button
          @click="clearLoadTenderFilters()"
          variant="outline-danger"
          class="mr-5"
          >Clear Filters</b-button
        >
      </b-col>
    </div>
    <b-row v-if="filterError" class="text-danger justify-content-center">
      An error unexpected error has occurred.
      <br />Please try again later or contact support.
    </b-row>
  </div>
</template>

<script>
//TODO: this filter stuff still needs refactored. To much repeated code.

import { mapActions, mapState, mapGetters } from "vuex";

import TfConsigneeMultiSelect from "@/components/multiselects/ConsigneeMultiSelectV2";
import TfCitiesMultiSelect from "@/components/multiselects/CitiesMultiSelect";
import TfStatesMultiSelect from "@/components/multiselects/StatesMultiSelect";

export default {
  name: "DefaultHeaderDropdownAccnt",
  props: ["hasSuppliers", "hasAccountOfs"],
  components: {
    TfConsigneeMultiSelect,
    TfCitiesMultiSelect,
    TfStatesMultiSelect
  },
  data: () => {
    return {
      filterError: false,
      filter: {
        selectedConsignees: [],
        selectedCities: [],
        selectedStates: [],
        activeOrdersOnly: false,
        includeBillToConsignees: false,
        includeAccountOfConsignees: true,
        includeSupplierOfConsignees: true
      }
    };
  },
  async mounted() {
    //load the objects back from state.

    this.filter = { ...this.filterObject };

    // if (!this.hasBillTos) {
    //   this.filter.includeAccountOfConsignees = true;
    //   this.filter.includeSupplierOfConsignees = true;
    // }

    this.setSelectedConsignees(this.filter.selectedConsignees);
    this.setSelectedCities(this.filter.selectedCities);
    this.setSelectedStates(this.filter.selectedStates);
  },
  methods: {
    ...mapActions("dashboard", ["setFilterObject", "setIsFilterApplied"]),
    ...mapActions("companyMultiSelects", [
      "setSelectedConsignees",
      "setSelectedStates",
      "setSelectedCities"
    ]),
    async applyLoadTenderFilters() {
      this.setFilterObject(this.filter);
      this.setIsFilterApplied(true);
      this.$emit("closePanel", true); //true here will trigger reload of orders.
    },
    async clearLoadTenderFilters() {
      this.setIsFilterApplied(false);

      this.filter = {
        selectedConsignees: [],
        selectedCities: [],
        selectedStates: [],
        activeOrdersOnly: false,
        includeBillToConsignees: false,
        includeAccountOfConsignees: false,
        includeSupplierOfConsignees: false
      };

      this.setFilterObject(this.filter);
      this.setSelectedConsignees([]);
      this.setSelectedCities([]);
      this.setSelectedStates([]);

      this.$emit("closePanel", true); //true here will trigger reload of orders.
    }
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("user", ["loggedInUser"]),
    ...mapState("dashboard", ["filterObject"]),
    ...mapState("companyMultiSelects", [
      "selectedCities",
      "selectedStates",
      "selectedConsignees",
      "activeOrdersOnly"
    ]),

    loggedInUserId() {
      return this.oidcUser.sub;
    },
    loggedInUserCustomerId() {
      return this.loggedInUser.customerId;
    }
  },
  watch: {
    selectedConsignees(newValue) {
      this.filter.selectedConsignees = newValue;
    },
    selectedCities(newValue) {
      this.filter.selectedCities = newValue;
    },
    selectedStates(newValue) {
      this.filter.selectedStates = newValue;
    }
  }
};
</script>

<style scoped>
</style>
