/* eslint-disable no-unused-vars */
<template>
  <div>
    <b-row
      v-for="(order, index) in orders.slice(0, ordersReturned)"
      :key="order.tmsOrderNumber"
      class="mx-0"
    >
      <b-form-checkbox
        v-if="showChecks"
        style="margin: 30px 0 0 0;"
        @change="orderChecked($event, order.tmsOrderNumber)"
        :checked="isChecked(order.tmsOrderNumber)"
        :key="orderSelectedKey"
      ></b-form-checkbox>

      <b-card class="card-style" :style="getWidth">
        <b-row class="text-right" v-if="windowWidth < 768">
          <b-col>
            <i
              v-if="order.paperwork"
              class="fas fa-file-image mr-2"
              v-b-popover.hover.html="paperworkAvailable"
              title="Paperwork Available"
            ></i>

            <b-badge
              class="badge-text"
              :variant="badgeColor(order.orderStatus)"
              >{{ orderStatus(order.orderStatus) }}</b-badge
            >
          </b-col>
        </b-row>
        <b-row
          class="align-items-center"
          @click="onCardClick(order, index)"
          style="cursor:pointer"
        >
          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="1" md="2" sm="1">
            <i
              v-if="order.paperwork && windowWidth >= 768"
              class="fas fa-file-image mr-2"
              v-b-popover.hover.html="paperworkAvailable"
              title="Paperwork Available"
            ></i>

            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
              >Order No. -</span
            >
            {{ order.tmsOrderNumber ? order.tmsOrderNumber : "N/A" }}
          </b-col>

          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="1" md="2" sm="1">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
              >BOLs</span
            >
            {{ renderColumnText(1,order) }}
          </b-col>

					<b-col :class="{ 'mt-2': windowWidth < 768 }" lg="1" md="2" sm="1">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
              >#3PO</span
            >
            {{ renderColumnText(2,order) }}
          </b-col>
					

          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="1" md="2" sm="1">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
              >Tender No. -</span
            >
            {{ renderColumnText(3,order) }}
          </b-col>

          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="2" md="2" sm="6" class="text-center">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
              >Load Window -</span
            >

            <span class="text-left">
              {{ loadWindowClientReadable(order.loadEarliestDate) }}
              <br />
              {{ loadWindowClientReadable(order.loadLatestDate) }}
              <br />
            </span>
          </b-col>

          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="2" md="2" sm="12" class="text-center">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
            >
              Delivery Date
              <br />
            </span>
            {{ order.startDate | formattedDate }} -
            <br v-if="windowWidth > 768" />
            {{ order.completionDate | formattedDate }}
          </b-col>

          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="2" md="2" sm="6" class="text-center">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
              >Delivery Site -</span
            >

            <span v-for="(item, index) in order.consignees" :key="index+' - '+item.id" class="text-center">
              {{ item.name }}
              <br />
            </span>

          </b-col>

          <b-col v-if="windowWidth > 768" lg="1" md="2" class="text-center ml-4">
            <div class="d-flex justify-content-between">
              <div>
                <b-badge
                  class="badge-text"
                  :variant="badgeColor(order.orderStatus)"
                  >{{ orderStatus(order.orderStatus) }}</b-badge
                >
              </div>

              <div>
                <b-button variant="link">
                  <i v-if="index !== expanded" class="fa fa-caret-down fa-lg"></i>
                  <i v-if="index === expanded" class="fa fa-caret-left fa-lg"></i>
                </b-button>
              </div>
            </div>
          </b-col>
					
        </b-row>
        <b-collapse :id="'order' + index" :visible="index === expanded">
          <b-row v-if="!isLoading && orderDetails">
            <b-col cols="12">
              <h4 class="text-primary mt-3">Products</h4>
            </b-col>
            <b-col v-if="windowWidth >= 768">
              <b-table
                :items="orderDetails.details"
                :fields="getFieldsOrderDetails(order)"
                :responsive="true"
                :small="true"
              >
                <template v-slot:cell(shipper)="row">
                  {{
                    row.value && row.value !== "CALLDIS"
                      ? row.value.name
                      : "N/A"
                  }}
                </template>

                <template v-slot:cell(supplier)="row" :visible="order.division!=='SPG'">
                  {{
                    row.value.id &&
                    row.value.id !== "CALLDIS"
                      ? row.value.name
                      : "N/A"
                  }}
                </template>

                <template v-slot:cell(arrival)="row">
                  <time>
                    {{ row.item.arrivalDate | formattedDate("MM/DD/YYYY hh:mm a") }}
                  </time>
                </template>

                <template v-slot:cell(accountof)="row"  :visible="order.division!=='SPG'">
                  {{ row.value ? row.value.name : "N/A" }}
                </template>

                <template v-slot:cell(deliverysite)="row">
                  <div class="text-wrap text-break" style="max-width:200px;">
                    {{ getDeliveryAddress(row.value, "N/A") }}
                  </div>
                </template>

                <template v-slot:cell(product)="row">
                  {{ row.value ? row.value.name : "N/A" }}
                </template>
                <template v-slot:cell(amount)="row">
                  {{ row.value}} {{row.item.amountUnitOfMeasure}}
                </template>
              </b-table>
            </b-col>
            <b-col v-if="windowWidth < 768">
              <MobileProductCards :products="orderDetails.details" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-alert
                class="mt-3"
                variant="danger"
                v-model="wasOrderLoadError"
              >
                <h4 class="alert-heading">Error!</h4>
                <p>There was an error loading the order details.</p>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="loadTheOrder(order)"
                  >Click here to try again</b-button
                >
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-if="isLoading" class="justify-content-center">
            <div class="loading-div">
              <div class="sk-wave">
                <div class="sk-rect sk-rect1"></div>
                &nbsp;
                <div class="sk-rect sk-rect2"></div>
                &nbsp;
                <div class="sk-rect sk-rect3"></div>
                &nbsp;
                <div class="sk-rect sk-rect4"></div>
                &nbsp;
              </div>
            </div>
          </b-row>
          <b-row class="mb-2">
            <b-col
              lg="3"
              md="3"
              sm="6"
              class="mt-3"
              v-if="orderDetails && orderDetails.thirdPartyPO"
            >
              <span class="text-primary font-weight-bold">Third Party PO#:</span>
              <br />
              {{ orderDetails.thirdPartyPO}}
            </b-col>
            <b-col
              lg="3"
              md="3"
              sm="6"
              :class="!orderDetails.thirdPartyPO ? 'mt-3 sm-12' : 'mt-3 sm-6'"
              v-if="orderDetails && orderDetails.po"
            >
              <span class="text-primary font-weight-bold">PO/Reference #:</span>
              <br />
              {{ orderDetails.po }}
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="12"
              class="mt-3"
              v-if="orderDetails && orderDetails.notes"
            >
              <span class="text-primary font-weight-bold"
                >Additional Notes & Remarks:</span
              >
              <br />
              {{ orderDetails.notes }}
            </b-col>
          </b-row>

          <b-row
            class="mt-5"
            v-if="(canCancelOrder && order.orderStatus === 'AVL' && order.division !== 'SPG') ||
            (canCancelOrder && order.division === 'SPG' && order.orderStatus === 'PND')"
          >
            <!-- <b-col v-if="userData && canDeleteOrders && !isLoading"> -->
            <b-col v-if="canDeleteOrders && !isLoading">
              <b-button
                variant="danger"
                :disabled="isCancelling"
                @click="showCancelConfirm()"
              >
                {{ isCancelling ? "Cancelling..." : "Cancel Order" }}
                <span
                  v-if="isCancelling"
                  class="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              </b-button>
            </b-col>

            <b-col class="text-right" v-if="canUpdateOrders && !isLoading">
              <a>


                <b-button id="btnEditOrder"
                          role="link"
                          :disabled="isCancelling"
                          @click="editorder(order)"
                          variant="outline-primary">Edit Order</b-button>
              </a>
            </b-col>
          </b-row>
          <b-row
            :class="{ 'mt-2': windowWidth < 768 }"
            v-else
            class="text-center text-primary"
          >
            <b-col>
              <strong>{{ getCantCancelOrEditText }}</strong>
            </b-col>
          </b-row>
          <b-row
            v-if="showBtnOrderDetails(order)"
          >
            <b-col class="text-center text-sm-right">
              <b-button
                @click="navigateToOrderDetails()"
                @contextmenu.prevent.stop="navigateToOrderDetailsFromContext()"
                variant="outline-primary"
                class="mt-2 mt-sm-0"
                >Order Details</b-button
              >
            </b-col>
          </b-row>
        </b-collapse>
        <b-row v-if="windowWidth < 768">
          <b-col class="text-center text-primary">
            <i
              v-if="index !== expanded"
              class="fa fa-chevron-down fa-lg mt-3"
            ></i>
            <i v-if="index === expanded" class="fa fa-chevron-up fa-lg mt-3"></i>
          </b-col>
        </b-row>
      </b-card>
    </b-row>

    <b-modal
      v-model="showOrderConfirmationModal"
      title="Cancel Order"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      size="md"
      @hide="closeModal()"
    >
      <b-row>
        <b-col>
          <p>
            Are you sure you want to cancel order
            {{ this.orderNumberToCancel }}?
          </p>
        </b-col>
      </b-row>

      <div slot="modal-footer" class="w-100">
        <b-button
          class="float-right ml-2 mr-2"
          variant="primary"
          @click="cancelOrder()"
          >Yes, Cancel</b-button
        >
        <b-button
          class="float-right"
          variant="outline-danger"
          @click="closeModal()"
          >Do Not Cancel</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import MobileProductCards from "@/components/order-tender/MobileProductCard";
import moment from "moment";
import { canUpdateOrders, canDeleteOrders } from "@/shared/utils/permissions";

import mixin from "@/shared/mixin";

import { mapActions, mapState } from "vuex";
import { divisions } from "@/shared/constants"
export default {
  name: 'ViewOrderTenderCard.vue',
  components: {
    MobileProductCards
  },
    props: ["orders", "showChecks", "ordersReturned"],
  created(){
    this.STATUS_STARTED = "STD"
    this.STATUS_DISPATCHED = "DSP"
    this.STATUS_PLANNED = "PLN"
    this.DIVISION_SPG = divisions.SPG
    this.DIVISION_FDG = divisions.FDG
  },
  data() {
    return {
      expanded: null,
      isLoading: false,
      isCancelling: false,
      windowWidth: window.innerWidth,
      //derToCancel: null,
      showOrderConfirmationModal: false,
      orderDetails: null,
      wasOrderLoadError: false,
      //selectedOrders: this.$store.state.order.selectedOrders,
      amountUnitOfMeasure: '',
      FDGfields: [
        "shipper",
        "supplier",
        "arrival",
        "accountOf",
        "deliverySite",
        "product",
        "amount"
      ],
      SPGfields: [
        "shipper",
        "arrival",
        "deliverySite",
        "product",
        "amount"
      ],

      orderSelectedKey: Math.random()
    };
  },
  mixins: [mixin],
  methods: {
    ...mapActions("dashboard", ["setOrderToZoom"]),
    editorder(order) {
      if (order.division === "FDG" && order.orderStatus !== "AVL" || (order.division === "SPG" && order.orderStatus !== "PND")) {
        this.$snotify.error("You cannot edit this order! Dispatch needs to be contacted to edit the order.", "Error");
      } else {
        this.$router.push({
          name: "edit-order-tender",
          params: { id: order.tmsOrderNumber, division: order.division }
        });
      }
    },
    showCancelConfirm() {
      this.showOrderConfirmationModal = true;
    },
    showBtnOrderDetails(order) {
      const notAllowedStatus = ['AVL', 'CAN']

      if (order.division === divisions.SPG) {
        notAllowedStatus.push('PND')
        return !notAllowedStatus.includes(order.orderStatus)
      }

      return !notAllowedStatus.includes(order.orderStatus)
    },
    closeModal() {
      this.showOrderConfirmationModal = false;
    },
    async cancelOrder() {
      this.closeModal();
      this.isCancelling = true;
      let oldStatus = this.orderDetails.status; //save this to set back in case it fails.

      try {
        this.orderDetails.status = "cancelled";

        const requestData = {
          id: this.orderDetails.kagOrderId,
          order: this.orderDetails,
          jwtToken: this.$store.state.user.jwtToken
        };

        const res = await this.$store.dispatch("order/cancelOrder", requestData);

        if (res.success) {
          this.$snotify.success(
            "Order " + this.orderDetails.kagOrderId + " has been cancelled.",
            "Success"
          );
        } else {
          this.orderDetails.status = oldStatus;

          this.$snotify.error(
            "Unable to cancel the order at this time. Please contact dispatch if you need immediate assistance.",
            "Error"
          );
        }
      } catch (error) {
        this.orderDetails.status = oldStatus;
        this.$snotify.error(
          "Unable to cancel the order at this time. Please contact dispatch if you need immediate assistance.",
          "Error"
        );
      }

      this.isCancelling = false;
    },
    async loadTheOrder(order) {
      this.isLoading = true;
      this.wasOrderLoadError = false;

      const requestData = {
        orderNumber: order.tmsOrderNumber,
        division: order.division,
        preferHeader: "return=freight-based"
      };

      await this.$store.dispatch(
        "order/getOrderDetails",
        requestData
      ).then((detail) => {
        this.orderDetails = detail

        this.setOrderToZoom(order);
      })
      .catch((reason) => {
        console.error(reason);
        this.orderDetails = null;
        this.wasOrderLoadError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });

    },
    async onCardClick(order, index) {
      this.expanded === index ? (this.expanded = null) : (this.expanded = index);

      // only load if expanded and click the different order
      if (
        this.expanded !== null &&
        (!this.orderDetails ||
          order.tmsOrderNumber !== this.orderDetails.kagOrderId)
      ) {
        await this.loadTheOrder(order);
      }
    },
    badgeColor(val) {
      switch (val) {
        case "AVL":
          return "primary";
        case "STD":
          return "warning";
        case "DSP":
          return "info";
        case "PLN":
          return "info";
        case "CMP":
          return "success";
        case "CAN":
          return "danger";
        case "PND":
          return "secondary";

        default:
          break;
      }
    },
    orderStatus(val) {
      switch (val) {
        case "AVL":
          return "Available";
        case "STD":
          return "Started";
        case "DSP":
          return "Dispatched";
        case "PLN":
          return "Planned";
        case "CMP":
          return "Completed";
        case "CAN":
          return "Cancelled";
        case "PND":
          return "Pending";

        default:
          break;
      }
    },
    isChecked(orderNumber) {
      return _.includes(this.$store.state.order.selectedOrders, orderNumber);
    },
    navigateToOrderDetails() {
      this.$router.push({
        name: "order-details",
        params: {
          id: this.orderDetails.kagOrderId,
          division: this.orderDetails.division
        }
      });
    },
    navigateToOrderDetailsFromContext() {
     
        const routeData = this.$router.resolve({
          name: "order-details",
          params: {
            id: this.orderDetails.kagOrderId,
            division: this.orderDetails.division
          }
        }).href;
        var fullUrl = window.location.origin + "" + routeData
        window.open(fullUrl, '_blank');
    },
    orderChecked(e, orderNumber) {
      const data = { val: e, orderNumbers: [orderNumber] };
      this.$store.dispatch("order/setOrderSelection", data);
      this.$emit("changed", data); // emit to parent
    },
    getFieldsOrderDetails(order){

      const mapEmitter = new Map([
        [
          this.DIVISION_FDG, () => {
            switch (order.orderStatus) {
              case this.STATUS_STARTED:
              case this.STATUS_DISPATCHED:
              case this.STATUS_PLANNED:
                return this.FDGfields;
              default:
                return this.FDGfields.filter(e => e !== "arrival");
            }
          }
        ],
        [
          this.DIVISION_SPG, () => {
            switch (order.orderStatus) {
              case this.STATUS_STARTED:
              case this.STATUS_DISPATCHED:
              case this.STATUS_PLANNED:
                return this.SPGfields;
              default:
                return this.SPGfields.filter(e => e !== "arrival");
            }
          }
        ]
      ]);
      return mapEmitter.get(order.division)();
    },
    loadWindowClientReadable(loadDateTime) {
      return moment(loadDateTime).format('MM/DD/YYYY h:mm a').toString()
    },
		renderColumnText(colNumber, order){
			if(this.refNumTypesSelected && order.referenceNumbers){
				const tempRefNumType = this.refNumTypesSelected.find(x => x.colNumber === colNumber);
				if(tempRefNumType){
					const referenceNumbers = order.referenceNumbers
						.filter(x => x.referenceType === tempRefNumType.abbr)
						.map(x => x.referenceNumber) // Extract reference numbers
						.join(', '); // Join with commas
					return referenceNumbers;
				}
			}
			switch(colNumber){
				case 1: return order.bols;
				case 2: return order.thirdPartyPO;
				case 3: return order.shipmentId;
				default: 'NOT SET UP'
			}
		}
  },
  filters:{
    formattedDate(value, format){
      //was: MM/DD/YYYY hh:mm a
      if(value){
        return moment(value)
          .format(format || "MM/DD/YYYY")
          .toString();
      } else {
        return 'N/A';
      }
    }
  },
  computed: {
		...mapState('user', {
			refNumTypesSelected: state => state.refNumTypesSelected
    }),
    canCancelOrder() {
      if (this.orderDetails) {
        const now = moment();
        const orderDate = moment(this.orderDetails.shipmentStartDateTime);
        return (
          orderDate.diff(now, "hours") > 6 &&
          (this.orderDetails.sender === "TRPFCS" || this.orderDetails.receiver === "TRPFCS")
        );
      }

      return false;
    },
    orderNumberToCancel() {
      if (!this.orderDetails) return "";
      return this.orderDetails.kagOrderId;
    },
    // userData() {
    //   return this.$store.state.user.userData;
    // },
    canDeleteOrders() {
      return canDeleteOrders(this.$store);
    },
    canUpdateOrders() {
      return canUpdateOrders(this.$store);
    },
    selectedOrders() {
      return this.$store.state.order.selectedOrders;
    },
    getCantCancelOrEditText() {
      if (this.orderDetails) {
        if (
          this.orderDetails.status === "CAN" ||
          this.orderDetails.status === "cancelled"
        )
          return "This order has been cancelled. Please call dispatch if you believe this is an error.";
      }
      return "This order cannot be edited or cancelled. For support please call dispatch.";
    },
    getWidth() {
      if (this.showChecks) {
        return "width:97%";
      }

      return "width:100%";
    },
    paperworkAvailable() {
      return "<p class='mb-5'>Paperwork is available for this order.</p>";
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
  },
	watch:{
		orders(){
			this.expanded = null;
		}
	}
};
</script>

<style src="spinkit/scss/spinkit.scss" lang="scss" />

<style scoped>
.loading-div {
  margin: 25px;
  min-width: 4vh;
}

.title-text {
  font-size: 16px;
}

.badge-text {
  font-size: 12px;
}
</style>
