<template>
  <div>
    <div class="here-map">
      <div ref="map" v-bind:style="{ width: width, height: height }"></div>
    </div>

    <b-row>
      <b-col md="1">
        <button
          class="btn btn-outline-info mt-2"
          size="sm"
          @click="populateMapFromData"
        >
          Reset Zoom
        </button>
      </b-col>
      <b-col>
        <b-card class="mt-2" no-body>
          <b-card-body class="p-2">
            <span v-show="showFdgLegend === true"> 
              <img
                height="25"
                width="25"
                src="/img/gas-pump-gray.png"
                alt="A consignee location."
                title="A consignee location."
              />
              Consignee
              <img
                height="25"
                width="25"
                src="/img/gas-pump.png"
                class="ml-3"
                alt="A consignee that has order(s) in a planned, dispatched or started status."
                title="A consignee that has order(s) in a planned, dispatched or started status."
              />
              Consignee with Active Order(s)
            </span>
            <span v-show="showSpgLegend === true"> 
          
              <img
                height="25"
                width="25"
                src="/img/spg-consignee-gray.png"
                alt="A consignee location."
                title="A consignee location."
              />
              Consignee
              <img
                height="25"
                width="25"
                src="/img/spg-consignee.png"
                class="ml-3"
                alt="A consignee that has order(s) in a planned, dispatched or started status."
                title="A consignee that has order(s) in a planned, dispatched or started status."
              />
              Consignee with Active Order(s)
            </span>
            <img
              height="25"
              width="25"
              src="/img/cluster-icon.png"
              class="ml-3"
              alt="Several objects in an area. Zoom in to disperse the cluster."
              title="Several objects in an area. Zoom in to disperse the cluster."
            />
            Multiple Consignees in this area
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/RepositoryFactory";
const ordersRepository = RepositoryFactory.get("ordersRepository");
import Details from "@/components/dashboard/OrderDetailsConsigneeView";
import Vue from "vue";

import store from "@/store/store";
import { mapState } from "vuex";

import { convertCanonicalToSomethingNotStupid } from "@/shared/utils/order-canonical-helper";

export default {
  components: {
    Details
  },
  name: "HereMap",
  data() {
    return {
      map: {},
      platform: {},
      openBubble: null,
      ui: null,
      
      mapObjectsGroup: new H.map.Group(),
      
      officesGroup: new H.map.Group(),
      
      shippersGroup: new H.map.Group(),
      
      consigneesGroup: new H.map.Group(),
      
      trucksGroup: new H.map.Group(),
      
      routesGroup: new H.map.Group(),
      
      routePinsGroup: new H.map.Group(),
      clusteredDataProvider: null,
      clusteringLayer: null,
      paddingFactor: 0.06,
      showFdgLegend : false,
      showSpgLegend : false
    };
  },
  props: {
    apiKey: String,
    lat: String,
    lng: String,
    width: String,
    height: String,
    orderStatus: String,
    division: String,
    mapJson: {}
  },
  created() {
    
    this.platform = new H.service.Platform({
      apikey: this.apiKey
    });
  },
  mounted() {
    //Default map types
    let defaultLayers = this.platform.createDefaultLayers();

    //Instantiate map
    
    this.map = new H.Map(this.$refs.map, defaultLayers.vector.normal.map, {
      zoom: 4,
      center: {
        lat: this.lat,
        lng: this.lng
      },
      pixelRatio: window.devicePixelRatio || 1
    });

    //Resize listener to ensure map occupies whole map container.
    window.addEventListener("resize", () => this.map.getViewPort().resize());

    //Events
    
    let behavior = new H.mapevents.Behavior(
      
      new H.mapevents.MapEvents(this.map)
    );

    //Default UI
    
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers, "en-US");

    this.populateMapFromData();
  },
  methods: {
    populateMapFromData() {
      //this.map.removeObjects(this.map.getObjects());

      if (this.clusteringLayer) this.map.removeLayer(this.clusteringLayer);

      if (!this.mapJson) return;
      let mapObjectData = this.mapJson;

      let spgIcon = new H.map.Icon("/img/spg-consignee-gray.png", {
        size: { w: 32, h: 32 }
      });
      let spgIconActive = new H.map.Icon("/img/spg-consignee.png", {
        size: { w: 32, h: 32 }
      });
      let gasIconActiveOrders = new H.map.Icon("/img/gas-pump.png", {
        size: { w: 24, h: 24 }
      });

      let gasIcon = new H.map.Icon("/img/gas-pump-gray.png", {
        size: { w: 24, h: 24 }
      });

      var dataPoints = [];


      mapObjectData.forEach(consignee => {
     
        var initData = {};
        if (consignee.division == 'FDG'){
          initData = { consignee: consignee,
            icon: consignee.orders.length > 0 ? gasIconActiveOrders : gasIcon
          };
          this.showFdgLegend =true;

        }
        if (consignee.division == 'SPG'){
          initData = { consignee: consignee,
            icon: consignee.orders.length > 0 ? spgIconActive : spgIcon
          };
          this.showSpgLegend = true;
        }
        const found = dataPoints.findIndex(
          element =>
            element.lat === consignee.latSeconds &&
            element.lng === consignee.longSeconds
        );

        if (found < 0) {
          dataPoints.push(
            
            new H.clustering.DataPoint(
              consignee.latSeconds,
              consignee.longSeconds,
              null,
              initData
            )
          );
        }
      });

      this.clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        clusteringOptions: {
          // Maximum radius of the neighbourhood
          eps: 32,
          // minimum weight of points required to form a cluster
          minWeight: 3
        }
      });

      this.clusteredDataProvider.addEventListener(
        "tap",
        this.showConsigneeInfo
      );

      var defaultTheme = this.clusteredDataProvider.getTheme();

      //custom theme
      var customTheme = {
        getClusterPresentation: function(cluster) {
          //Keep the default theme for clusters
          var clusterMarker = defaultTheme.getClusterPresentation.call(
            defaultTheme,
            cluster
          );
          return clusterMarker;
        },
        getNoisePresentation: function(noisePoint) {
          //get the default noise markers

          var data = noisePoint.getData(),
            // Create a marker for the noisePoint
            
            noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
              // Use min zoom from a noise point
              // to show it correctly at certain zoom levels:
              min: noisePoint.getMinZoom(),
              icon: data.icon
            });

          // Link a data from the point to the marker
          // to make it accessible inside onMarkerClick
          noiseMarker.setData(data);

          return noiseMarker;
        }
      };

      //set the custom theme
      this.clusteredDataProvider.setTheme(customTheme);

      // Create a layer tha will consume objects from our clustering provider
      
      this.clusteringLayer = new H.map.layer.ObjectLayer(
        this.clusteredDataProvider
      );

      //  this.adjustMapToViewAllObjects();

      this.clusteredDataProvider.addEventListener("update", evt => {
        // target is Provider itself after clustering is completed

        if (evt.target === this.clusteredDataProvider) {
          var bounds = this.clusteredDataProvider
            .getRootGroup()
            .getBoundingBox();

          let paddedRectangle = new H.geo.Rect(
            bounds.getTop() + this.paddingFactor,
            bounds.getLeft() - this.paddingFactor,
            bounds.getBottom() - this.paddingFactor,
            bounds.getRight() + this.paddingFactor
          );

          this.map.getViewModel().setLookAtData({
            //zoom: 15,
            bounds: paddedRectangle
          });
        }
      });

      this.map.addLayer(this.clusteringLayer);
    },

    async showConsigneeInfo(evt) {
      if (this.openBubble !== null) {
        this.ui.removeBubble(this.openBubble);
        this.openBubble.dispose();
      }

      let data = evt.target.getData();
      if (!data.consignee) return;

      let a = await ordersRepository.get(
        ["STD", "DSP", "PLN"],
        [data.consignee.id],
        1,
        6,
        "return=freight-based"
      );

      if (a.success) {
        this.zoomToPoint(
          data.consignee.latSeconds,
          data.consignee.longSeconds,
          this.map.getZoom()
        );
      } else {
        this.$snotify.error(
          "There was an error retrieving order data.",
          "Error"
        );
      }

      let infoBubbleOrders = a.data;

      var orderDetailsComponent = new Vue({
        ...Details,
        parent: this,
        propsData: {
          orders: infoBubbleOrders,
          consignee: data.consignee
        }
      }).$mount();

      let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content: orderDetailsComponent.$el
      });
      bubble.addClass("infoBubble");
      this.openBubble = bubble;
      this.ui.addBubble(this.openBubble);
    },

    adjustMapToViewAllObjects() {
      var bounds = this.clusteredDataProvider.getRootGroup().getBoundingBox();

      let paddedRectangle = new H.geo.Rect(
        bounds.getTop() + this.paddingFactor,
        bounds.getLeft() - this.paddingFactor,
        bounds.getBottom() - this.paddingFactor,
        bounds.getRight() + this.paddingFactor
      );

      this.map.getViewModel().setLookAtData({
        //zoom: 15,
        bounds: paddedRectangle
      });
    },

    zoomToPoint(lat, long, zoomLevel) {
      this.map.setCenter({ lat: lat, lng: long });
      this.map.setZoom(zoomLevel);
    }
  },
  computed: {
    ...mapState("dashboard", ["orderToZoom"])
  },
  watch: {
    orderToZoom: function(val) {
      this.zoomToPoint(
        val.consignees[0].latitude,
        val.consignees[0].longitude,
        14
      );
    },
    
    mapJson: function(val) {
      this.populateMapFromData();
    }
  }
};
</script>

<style scoped>
.infoBubble {
  width: 400px;
}

.bold {
  font-weight: bold;
}

span.when {
  font-size: 0.8em;
}
</style>
